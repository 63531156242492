import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import '@vueform/multiselect/themes/tailwind.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'tippy.js/dist/tippy.css';
import '@vuepic/vue-datepicker/dist/main.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import fr from "moment/dist/locale/fr"
import * as Sentry from "@sentry/vue";
import Auth from './plugins/auth'
import Toast from 'primevue/toast';
import PageLoading from '@/Components/PageLoading.vue'
//import VueMatomo from 'vue-matomo'
                                                        
Sentry.init({                                           
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  environment: import.meta.env.APP_ENV,  
});

const appName = import.meta.env.VITE_APP_NAME || 'MPP';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob([
        './Pages/**/*.vue',
        '../images/**',
    ])),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Auth)
            .use(PrimeVue)
            .use(ToastService)
            .use(ConfirmationService)
            .use(VueGoogleMaps, {
                load: {
                    key: "AIzaSyD6wD5EVIJJ2vf2PS6RDgTAVS0oYl13eo8", 
                    libraries: "places"
                }
            })
            // .use(VueMatomo, {
            //     host: 'https://stats.mpp.paris/',
            //     siteId: 2
            // })
            .component('Toast', Toast)
            //.component('PageLoading', PageLoading)
            .mount(el);
    },
    progress: {
        color: '#6366f1',
        //showSpinner: true
    },
});


// CUSTOM PAGE LOADING
let pageLoadingInstance;

document.addEventListener('DOMContentLoaded', () => {
    const pageLoadingContainer = document.createElement('div');
    document.body.appendChild(pageLoadingContainer);
    pageLoadingInstance = createApp(PageLoading).mount(pageLoadingContainer);
});
router.on('start', (event) => {
    pageLoadingInstance.start();
    //console.log('Starting...');
})

router.on('finish', (event) => {
    pageLoadingInstance.finish();
    //console.log(`End.`)
})