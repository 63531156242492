import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";

export default {
    install: (app) => {
        const user = computed(() => usePage().props.auth.user);
        const roles = computed(() => user.value.roles.map(role => role.name));
        const permissions = computed(() => user.value.permissions.map(permission => permission.name));

        app.config.globalProperties.$auth = {
            user,
            roles,
            permissions,
            isRole(allowedRoles) {
                return allowedRoles.some(role => roles.value.includes(role))
            },
            hasPermission(allowedPermissions) {
                return allowedPermissions.some(permission => permissions.value.includes(permission))
            }
        };
    }
};
